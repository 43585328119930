import { useState,useEffect } from "react"
import useMediaQuery from "../useMedia";
import { maxRowBasedquery } from "../mediamax";
export default function AmariWidgetPopup(props){
    const maxRowBased = useMediaQuery(maxRowBasedquery)

    const urlnames = ["CaesarAI","Amari Movies","CaesarAIUniAdmin","Amari FinanceGraphs","CaesarAI Engine World","QA TimeTables","Amari Art","KayinDB"]
    const urls = ["caesarai","amarimovies","caesaraiuniadmin","amarifinancegraphs","managecaesaraiengineworld","qatimetables","amarigallery","kayindb"]
    const [widgetclosed,setWidgetClosed]= useState(false);
    const handleExitClick = () =>{
        setWidgetClosed(true)    
    }
    useEffect(() =>{
        if (widgetclosed === true){
            props.setWidgetOpen(false)
            props.setWidgetWidth("60px")
            props.setWidgetHeight("100px")
            props.setCursorState("pointer")
        }

    },[widgetclosed])
    
    const renderUrls = (ind) =>{

        if (ind === 0){
            return(
                <div style={{display:"flex",gap:"10px"}}>
                    {maxRowBased ? 
                    <a target="_blank" rel="noopener noreferrer" style={{color:"black"}} key={ind} href={`/${urls[ind]}`}>
                    <div style={{backgroundColor:"white",padding:"5px",borderRadius:"2px",width:"165px"}}>
                        {urlnames[ind]}
                    </div>
                    </a>
                    :
                    <a style={{color:"black"}} key={ind} href={`/${urls[ind]}`}>
                    <div style={{backgroundColor:"white",padding:"5px",borderRadius:"2px",width:"160px"}}>
                    {urlnames[ind]}
                    </div>
                    </a>
                    
                    }
                    <div onClick={() =>{handleExitClick()}} style={{marginRight:"6px",cursor:"pointer",marginTop:"5px"}}>x</div>
                </div>
            )
        }
        else if (urls[ind].includes("movies") || urls[ind].includes("kayindb")){
            return(
            <div>
                {maxRowBased ? 
                <a target="_blank" rel="noopener noreferrer" style={{color:"black"}} key={ind} href={`/${urls[ind]}`}>
                <div style={{backgroundColor:"white",padding:"5px",borderRadius:"2px"}}>
                    {urlnames[ind]}
                </div>
                </a>
                    :
                <a style={{color:"black"}} key={ind} href={`/${urls[ind]}`}>
                <div style={{backgroundColor:"white",padding:"5px",borderRadius:"2px"}}>
                {urlnames[ind]}
                </div>
                </a>}
            </div>
            )
        }
        else if (urls[ind].includes("amarigallery")){
            return(
            <div>
                {maxRowBased ? 
                <a target="_blank" rel="noopener noreferrer" style={{color:"black"}} key={ind} href={`/${urls[ind]}`}>
                <div style={{backgroundColor:"white",padding:"5px",borderRadius:"2px"}}>
                    {urlnames[ind]}
                </div>
                </a>
                    :
                <a style={{color:"black"}} key={ind} href={`/${urls[ind]}`}>
                <div style={{backgroundColor:"white",padding:"5px",borderRadius:"2px"}}>
                {urlnames[ind]}
                </div>
                </a>}
            </div>
            )
        }
        else{
            return(
            <div>
                {maxRowBased ? 
                <a target="_blank" rel="noopener noreferrer" style={{color:"black"}} key={ind} href={`/amarisecurity?h=${urls[ind]}`}>
                <div style={{backgroundColor:"white",padding:"5px",borderRadius:"2px"}}>
                    {urlnames[ind]}
                </div>
                </a>
                    :
                <a style={{color:"black"}} key={ind} href={`/amarisecurity?h=${urls[ind]}`}>
                <div style={{backgroundColor:"white",padding:"5px",borderRadius:"2px"}}>
                {urlnames[ind]}
                </div>
                </a>}
            </div>
            )
        }

    }

    return(
        <div className="AmariWidgetPopup" style={{border:"1px solid white",padding:"5px",backgroundColor:"#3296c0",borderTopRightRadius:"5px",borderBottomRightRadius:"5px",width:props.widgetwidth,height:props.widgetheight}}>

           
        <div style={{display:"flex",flexDirection:"column",gap:"13px"}}>
            
            {urlnames.map((urlname,ind) =>renderUrls(ind))}
        </div>
      </div>

    )
}