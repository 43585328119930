// drag drop file component
import React from "react";
import "./draganddrop.css"
import axios from "axios";

import LoadingSpinner from "./loaderspinner";
export default function DragDropFile(props) {
    // drag state
    const [dragActive, setDragActive] = React.useState(false);
    
    
    
    
    const csv_to_graph = async () =>{
        if (props.csvdata !== ""){
            props.setLoading(true)

            const titlesend = props.title === "" ? props.filename : props.title 
            const form = new FormData();
            form.append("file", props.csvdata);
            form.append("title",titlesend);
            form.append("filesize",props.filesize);
            form.append("usebase64","true");

            const response = await axios({
                method: 'post',
                url: 'https://amarifinancepots-662756251108.us-central1.run.app/financegraphchart',
                data: form,
                
                headers: {
                    'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
                        responseType: 'arraybuffer'
                },
            });
            
            const result = response.data
            if ("error" in result){
                alert(result.error)
            }
            else{
                //console.log(result)
                props.setGraphImage(result.image)
                props.setLoading(false);
            }
            
        }
        else{
            alert("Complete all fields.")
            props.setLoading(false);
        }
    }
    const saveFile = async () => {
        const blob = new Blob([props.csvdata], {type : 'text/csv'});
        const a = document.createElement('a'); 
        a.download = props.title === "" ?props.filename:props.title + ".csv";
        a.href = URL.createObjectURL(blob);
        a.addEventListener('click', (e) => {
          setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
        });
        a.click();
      };
    const saveDB = async () =>{
      console.log(props.csvdata)
      console.log(props.graphimage)
      if (props.csvdata !== ""  && props.graphimage !== null){
        props.setLoading(true)
        console.log("hello")
        const titlesend = props.title === "" ? props.filename : props.title 
        const form = new FormData();
        form.append("filename", titlesend);
        form.append("file",props.csvdata);
        form.append("filegraph",props.graphimage.replace("data:image/png;base64,",""));
        console.log(typeof(props.graphimage),"image")
  
        const response = await axios({
          method: 'post',
          url: 'https://amarifinancepots-662756251108.us-central1.run.app/postfinance',
          data: form,
          
          headers: {
              'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
                  responseType: 'arraybuffer'
          },
      });

      console.log(response.data)
      if ("error" in response.data){
        alert(response.data.error)
      }
      else{
        alert(response.data.message)
        props.setLoading(false)
      }
}
else{
  alert("Generate graph image.")
}
      

    }
      
      
      
      
      
      
    // ref
    const inputRef = React.useRef(null);
    const handleFiles = (files) =>{
        props.setFileName(files[0].name.replace(".csv",""));
        const reader = new FileReader()
        reader.onload = async (e) => { 
          const text = (e.target.result)
          props.setCSVData(text)
        };
        reader.readAsText(files[0])


    }
    
    // handle drag events
    const handleDrag = function(e) {
      e.preventDefault();
      e.stopPropagation();
      if (e.type === "dragenter" || e.type === "dragover") {
        setDragActive(true);
      } else if (e.type === "dragleave") {
        setDragActive(false);
      }
    };
    
    // triggers when file is dropped
    const handleDrop = function(e) {
      e.preventDefault();
      e.stopPropagation();
      setDragActive(false);
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        handleFiles(e.dataTransfer.files);
      }
    };
    
    // triggers when file is selected with click
    const handleChange = function(e) {
      e.preventDefault();
      if (e.target.files && e.target.files[0]) {
        handleFiles(e.target.files);
      }
    };
    
  // triggers the input when the button is clicked
    const onButtonClick = () => {
      inputRef.current.click();
    };
    
    return (
        <div style={{width:props.media ? "auto":"200px"}}>
            {props.csvdata !== "" ?
            <div style={{margin:props.media? "40px":"23px",width:props.media?"550px":"160px",height:"300px"}}>
                <textarea onChange={(e) =>{props.setCSVData(e.target.value)}} value={props.csvdata} style={{height:"100%",width:"100%"}}>
                </textarea>
                <button onClick={()=>{csv_to_graph()}}>Submit</button>
                <button onClick={()=>{props.setCSVData("");props.setGraphImage(null)}}>Remove</button>
                <button onClick={()=>{saveFile();}}>Save As</button>
                <button onClick={()=>{saveDB();}}>Save DB</button>
                {props.loading === true && <LoadingSpinner/>}

            </div> 
            :
            <form  id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                <input ref={inputRef} type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" id="input-file-upload" multiple={true} onChange={handleChange} />
                <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : "" }>
                <div>
                    <p>Drag and drop your file here or</p>
                    <button className="upload-button" onClick={onButtonClick}>Upload a file</button>
                </div> 
                </label>
                { dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
            </form>}
        </div>

    );
  };