import logo from '../imgs/Amari-logo.svg'
import { useState ,useEffect} from 'react'
import DragDropFile from "./draganddrop"
import useMediaQuery from "../useMedia"
import {maxRowBasedquery} from "../mediamax"
import Select from 'react-select'
import axios from 'axios'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import WalletIcon from '@mui/icons-material/Wallet';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Alert, Button } from 'react-bootstrap'
import CancelIcon from '@mui/icons-material/Cancel';
import Spinner from 'react-bootstrap/Spinner';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CloudIcon from '@mui/icons-material/Cloud';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import { Await, useNavigate } from 'react-router'
import PlaidLogo from "./Plaid.png"
import PaidIcon from '@mui/icons-material/Paid';
import PotManage from './PotManage'
export default function AmariFinanceGraphs(){
    const navigate = useNavigate()
    const [title,setTitle] = useState("");
    const [filesize,setFileSize] = useState("[10,10]");
    const [graphimage,setGraphImage] = useState(null);
    const [filename,setFileName] = useState("");
    const media = useMediaQuery(maxRowBasedquery)
    const [errormessage,setErrorMessage] = useState("");
    const [filenameoptions,setFilenameOptions] = useState("");
    const [csvdata,setCSVData] = useState("");
    const [inpnewpot,setInputNewPot] = useState(false);
    const [pots,setPots] = useState({})
    // {"Savings":400,"Car":100,"Barbers":16,"Mortgage Deposit":277,"Mummy Las Vegas Saving":125,"Work Food":28,"Transport WORK":40.8}
    const [newpotname,setNewPotName] = useState("");
    const [newpotamount,setNewPotAmount] = useState(0);
    const [openpots,setOpenPots] = useState(false);
    const [submittingpots,setSubmittingPots] = useState(false);
    const [startingbalance,setStartingBalance] = useState("Fetching"); // 11_597.50
    const [startingmonthlybalance,setStartingMonthlyBalance] = useState("...")
    const [disablesavedb,setDisableSaveDB] = useState(false);
    const [deductions,setDeductions] = useState(0);
    //const []
    const [loading,setLoading] = useState(false)
    
    const csv_to_graph_pots = async (potstring,final_balance) =>{
        
            setLoading(true)

            const form = new FormData();
            form.append("file", potstring);
            form.append("title",title);
            form.append("filesize",filesize);
            form.append("usebase64","true");

            const response = await axios({
                method: 'post',
                url: 'https://amarifinancepots-662756251108.us-central1.run.app/financegraphchart',
                data: form,
                
                headers: {
                    'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
                        responseType: 'arraybuffer'
                },
            });
            
            const result = response.data
            if ("error" in result){
                alert(result.error)
            }
            else{
                //console.log(result)
                let image = result.image
                setGraphImage(image)
                if (disablesavedb === false){
                    const form = new FormData();
                    form.append("filename", title);
                    form.append("file",potstring);
                    form.append("filegraph",image.replace("data:image/png;base64,",""));
                    console.log(typeof(image),"image")
              
                    const response = await axios({
                      method: 'post',
                      url: 'https://amarifinancepots-662756251108.us-central1.run.app/postfinance',
                      data: form,
                      
                      headers: {
                          'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
                              responseType: 'arraybuffer'
                      },
                  });
            
                  console.log(response.data)
                  if ("error" in response.data){
                    alert(response.data.error)
                  }
                  else{
                    alert(`Pots deposited into Monzo! Balance: £${final_balance} and ${response.data.message}`)
                    setLoading(false)
                  }
                }
                else{
                    alert(`Pots deposited into Monzo! Balance: £${final_balance}.`)
                    setLoading(false)
                }


            }
            
    }

    function capitalizeLetter(string) {
        return string[0].toUpperCase() +string.slice(1)
    }
    const addpot = async () =>{
        if (newpotname !== "" ){ // && newpotamount !== 0
            let newpot ={}
            newpot[newpotname] = newpotamount 
            //setNewPotName("");setNewPotAmount
            const response = await axios.get(`https://amarifinancegocardless-qqbn26mgpa-uc.a.run.app/api/create_pot?pot_name=${newpotname}&value=${newpotamount}`,)
            let result = response.data
            console.log(result)
            setPots(Object.assign({}, pots, newpot))
            setNewPotAmount(0);
            setNewPotName("");
        }
    }
   
    const getallfinancenames = async () =>{
        console.log("financenames")
        const response = await axios.get("https://amarifinancepots-662756251108.us-central1.run.app/getallfinancenames")
        console.log("finance banes")
        if ("error" in response.data){
            setErrorMessage(response.data.error)

        }
        else{

            const filenames = response.data.filenames
            const fileoptions = filenames.map((filename) =>{return({"value":filename["filename"],"label":capitalizeLetter(filename["filename"])})})
            setFilenameOptions(fileoptions)
        }
    }
    const getfileselect = async (e) =>{
        const filenameselect = e.value
        //console.log(e.value)
        setFileName(filenameselect)
        const response = await axios.get(`https://amarifinancepots-662756251108.us-central1.run.app/getfinance?filename=${filenameselect}`)
        if ("error" in response.data){
            setErrorMessage(response.data.error)

        }
        else{
            const financedata = response.data
            setCSVData(financedata.file)
            setGraphImage("data:image/png;base64,"+financedata.filegraphimg)
        }

    }
    const deletefinance = async () =>{
        if (filename !== ""){
            const response = await axios.delete(`https://amarifinancepots-662756251108.us-central1.run.app/deletefinance?filename=${filename}`)
        if ("error" in response.data){
            alert(response.data.error)

        }
        else{
            alert(response.data.message)
     
        }
    }
    }
    const checkDelete = () =>{
        
        var answer = window.confirm("Delete Data From DB?");
        if (answer) {
            deletefinance()
        }
        else {
            //some code
        }
    }
    const submitPots = async () =>{
        if (title !== "" || disablesavedb === true){
        setSubmittingPots(true)
 
        let potstring = `Year|expense_name|expenses|branch_num|parent_split_point|description\n2023|annual_salary|${startingbalance}|income|Take Home Income|NotA\n`
        for (const [key, value] of Object.entries(pots)) {
            let graphstring = `2023|${key}|${value}|tax|Take Home Income\n`
            potstring += graphstring
            
          }
        let pot_total = Object.values(pots).reduce((a, b) => a + b, 0)
          let final_balance = startingbalance - pot_total
          if (disablesavedb === false){
          await csv_to_graph_pots(potstring,final_balance)
          }
          else{
              alert(`Final Balance:£${final_balance}. Graph Not created.`)
              setLoading(false)
          }
          setSubmittingPots(false)
    }
    else{
        alert("Complete all fields.")
        setLoading(false);
        setSubmittingPots(false)
    }
    }
    const handlepotchange = (key,value) =>{
        //console.log(key,value)
        pots[key] = parseFloat(value)
        setPots(pots)

    }
    const remove_deducted_if_exists = async (potname) =>{
        const response = await axios.get(`https://amarifinancegocardless-662756251108.us-central1.run.app/api/check_deduction?pot_name=${potname}`)
        let resultcheck = response.data
        if (resultcheck["message"] === "true"){
            const response = await axios.get(`https://amarifinancegocardless-662756251108.us-central1.run.app/api/delete_deduction?pot_name=${potname}`)
            let result = response.data
            if ("error" in result){
                alert(result.error)
            }
            else{
                const regex = /£(\d+)/;
                const deduct_exists = regex.test(potname)
                if (deduct_exists){
                    const deduction = potname.match(regex)[1]
                    setDeductions(deductions - deduction)
                }
                else{
                    alert ("Pot name needs £<number>")
                }
         
     
            }
    
        }


    }
    const deletepot = async (key) =>{
        let potstate = {...pots};
        const response = await axios.get(`https://amarifinancegocardless-qqbn26mgpa-uc.a.run.app/api/delete_pot?pot_name=${key}`)
        await remove_deducted_if_exists(key)
        let result = response.data
        //console.log(result)
        delete potstate[key];
        setPots(potstate)
    }
    
    const calculate_pots = async (requisition_id) =>{
        const response = await axios.get(`https://amarifinancegocardless-qqbn26mgpa-uc.a.run.app/api/calculate_pots?requisition_id=${requisition_id}`)
        let result = response.data
        //console.log(result)
        if ("error" in result){
            //console.log()
            if ("display_message" in result.error){
                alert(result.error.display_message)

            }
            else{
                alert(result.error)

            }
            
        }
        else{
            setStartingBalance(result.debit_balance)
            if (result.pots.length > 0){
            let pot = Object.assign({}, ...result.pots)
            let valueToInt = function(spy){
                Object.keys(spy).forEach(function(key){ spy[key] = parseFloat(spy[key]) });
                return spy;
              }
              let final_pot = valueToInt(pot)
            setPots(final_pot)}
            else{
                setPots({})
            }

        }
    }
    const getpots = async () =>{
        const response_req_id = await axios.get("https://amarifinancegocardless-qqbn26mgpa-uc.a.run.app/api/v1/get_requisition_id")
        let result_req_id = response_req_id.data
        //console.log(response_req_id)
        if (result_req_id["expired"] === "false"){
            await calculate_pots(result_req_id["requisition_id"])
        }
        else{
            alert("Auth requisition id has been expired. Complete auth flow on mobile using icon. Refresh page when finished.")
        }
    }
    const refresh_requisition_id = async () =>{
        const response= await axios.get("https://amarifinancegocardless-qqbn26mgpa-uc.a.run.app/api/v1/bankauthflowmobile")
        let result = response.data
        let auth_link = result.auth_link
        window.open(auth_link, '_blank');


        
    }
    const deductpotstotal = async (potname,value,setIsPotDeducted) =>{
        const regex = /£(\d+)/;
        const deduct_exists = regex.test(potname)
        if (deduct_exists){
            const deduction = potname.match(regex)[1]
            const response = await axios.get(`https://amarifinancegocardless-662756251108.us-central1.run.app/api/create_deduction?pot_name=${potname}&value=${deduction}`)
            let result = response.data
            if ("error" in result){
                alert(result.error)
            }
            else{
                setDeductions(deductions + parseFloat(deduction))
                setIsPotDeducted(true)
            }
        }
        else{
            alert ("Pot name needs £<number>")
        }
 
    


    }
    const getdeductions = async () =>{
        const response = await axios.get("https://amarifinancegocardless-662756251108.us-central1.run.app/api/get_deduction_total")
        let result = response.data
        if ("message" in result){
            setDeductions(0)
        }
        else if ("deductions" in result){
            setDeductions(result.deductions)
        }
        else if ("error" in result){
            alert(result.error)
        }
        
    }
    useEffect(() =>{
        getallfinancenames()
 
        
        
        
        getpots()
        //setPots({"Spending(£200/mo)":1000,"Housing":300})
        //setStartingBalance(11_000)
        setStartingMonthlyBalance(1832.34)
        
        getdeductions()
    },[])
    const movetoplaid =() =>{
        navigate('/amariplaid', { state: { authed: "true"} });
    }

    return(
        <div style={{backgroundColor:"#1e1e1e",minHeight:graphimage === null ? media ? window.innerHeight :"270vh": media ? "135vh" :"320vh"}}>
           
            <div style={{position:"relative",top:"30px",left:"7.5%",width:"85%"}}>
                <div style={{display:"flex"}}>
                <a style={{cursor:"pointer"}} href='/'><img src={logo} style={{width:"100px",width:"100px"}}></img></a>
                <img onClick={() =>{refresh_requisition_id()}} style={{marginLeft:"auto",width:"100px",height:"100px",cursor:"pointer"}} src={PlaidLogo}></img>
                </div>
            </div>
            
            <div style={{position:"relative",top:"70px",left:"6.5%",width:"55%"}}>
                <div style={{display:"flex",flexDirection:"column",gap:"10px"}}>
                <h3 style={{color:"white"}}>Amari Finance Graphs</h3>
                
                <input value={title} onChange={(e) =>{setTitle(e.target.value)}} placeholder='Enter Graph Title:'></input>
                <input  onChange={(e) =>{setFileSize(e.target.value)}} placeholder='Enter File Size[10,10]:'></input>

                {filenameoptions !== "" && 
                <div style={{display:"flex",gap:"7px"}}>
                <Select styles={{width:"90%"}} onChange={(e) => {getfileselect(e)}} options={filenameoptions} />
                <button onClick={() => {checkDelete()}} style={{backgroundColor:"white",border:"1px solid black",color:"grey",borderRadius:"5px"}}>RemoveDB</button>
                </div>}
                {openpots === false ?
                <div style={{display:"flex",gap:"15px"}}>
                <a onClick={() =>{setOpenPots(true)}} style={{cursor:"pointer"}}>
                    <WalletIcon style={{color:"white"}}/>
                </a>
                {
                    disablesavedb === false ?
                    <a onClick={() =>{setDisableSaveDB(true)}} style={{cursor:"pointer"}}>
                    <CloudIcon style={{color:"white"}}/>
                    </a>:
                    <a onClick={() =>{setDisableSaveDB(false)}} style={{cursor:"pointer"}}>
                    <CloudQueueIcon style={{color:"white"}}/>
                    </a>
                }

                </div>

                :
                <div>
                    <input type='number' style={{width:"280px"}} onChange={(e) =>{setStartingBalance(e.target.value)}} placeholder={`Starting Balance(£${startingbalance}/${startingmonthlybalance}):`}></input><KeyboardArrowRightIcon style={{color:"white"}}/>
                    <div style={{display: "grid",gridTemplateColumns: `repeat(${media ? 2 : 1}, minmax(200px,${media ? "250px": "350px"}))`,marginTop:"10px",gridGap: "30px"}}>
                        {
                        Object.entries(pots)
                        .map(([key, value]) => {
                    

                            return(
                                <PotManage key={key} potname={key} value={value} deletepot={deletepot} deductpotstotal={deductpotstotal} deductions={deductions} setDeductions={setDeductions} startingmonthlybalance={startingmonthlybalance}/>
                        )} )
                        }

                    </div>
                    {inpnewpot === false ?
                    <div style={{display:"flex",gap:"10px"}}>
                    {submittingpots === false ?
                    <Button onClick={() =>{submitPots()}} style={{fontSize:"12px"}}>Submit Monthly Graph</Button>:
                    <Spinner style={{color:"blue"}} animation="border" />}
                    
                    <a style={{cursor:"pointer",position:"relative",top:"5px"}} onClick={() =>{setInputNewPot(true)}}>
                        <AddCircleOutlineIcon style={{color:"white"}}/>
                    </a>
                    <a style={{cursor:"pointer",position:"relative",top:"5px"}} onClick={() =>{setOpenPots(false);setInputNewPot(false);setNewPotName("");setNewPotAmount(0)}}>
                        <HighlightOffIcon style={{color:"white"}}/>
                    </a>
                    <p style={{color:"white",position:"relative",top:"5px"}}>Pots: {Object.values(pots).reduce((partialSum, a) => partialSum + a, 0).toFixed(2)} | Remaining: {(startingbalance - Object.values(pots).reduce((partialSum, a) => partialSum + a, 0)).toFixed(2)} | Monthly Balance: {startingmonthlybalance - deductions} </p>
                    </div>
                        :
                    <div >
                        <div style={{display:"flex",gap:"5px",marginTop:"10px"}}>
                        <input onChange={(e) =>{setNewPotName(e.target.value.replace(":","").replace(";","").replace("'",""))}} placeholder='New Pot:' value={newpotname}></input>
                        <input type='number' onChange={(e) =>{setNewPotAmount(parseFloat(e.target.value))}} style={{width:media ? "300px": "100px"}} placeholder='Amount:' value={newpotamount}></input>
                        <p onClick={() =>{setInputNewPot(false);setNewPotName("");setNewPotAmount("")}} style={{color:"white",position:"relative",top:"5px",left:"2px",cursor:"pointer"}}>x</p>
                        </div>
                        <div style={{display:"flex",gap:"10px",marginTop:"10px"}}>
                            <Button onClick={() =>{addpot()}} style={{color:"white",backgroundColor:"green",border:"1px solid green"}}>Add</Button>
                            <p style={{color:"white",position:"relative",top:"8px"}}>Pots: {Object.values(pots).reduce((partialSum, a) => partialSum + a, 0).toFixed(2)} | Remaining: {(startingbalance - Object.values(pots).reduce((partialSum, a) => partialSum + a, 0) ).toFixed(2)} | Monthly Balance: {startingmonthlybalance - deductions}</p>
                        </div>

                    </div>
                    }
                </div>
                }
                </div>
                

            </div>

                            
            <div style={{position:"relative",top:"100px",left:"6.5%",width:media ? "90%":"60%",left:"80px"}}>
                <div style={{display:"flex",flexDirection:media ? "row":"column"}}>
                    
                    <div style={{display:"flex",flexDirection:filename !== ""?"column":"row",justifyContent:"center",alignItems:"center",width:"91%",height:"500px",backgroundColor:"white",borderRadius:"10px"}}>
                        {filename !== "" && <h2 style={{overflowWrap: "break-word",fontSize:"12px"}}>{filename}</h2>}
                        <DragDropFile  setLoading={setLoading} loading={loading} setCSVData={setCSVData} csvdata={csvdata} media={media} title={title} filesize={filesize} graphimage={graphimage} setGraphImage={setGraphImage} setFileName={setFileName} filename={filename} />

                    </div>
                    <div style={{position:"relative",left:media ? "10px":"0px",width:"49%",height:"500px",minHeight:media ? "auto":"850px",top:media ? "0px":"10px",backgroundColor:"white",borderRadius:"10px",width:"91%"}}>
                        <div style={{padding:"30px"}}>
                            <div style={{position:"relative",top:"50px",display:"flex",flexDirection:"column"}}>
                                <h2 style={{overflowWrap: "break-word",fontSize:"12px"}}>Example Format:</h2>
                                <strong><p style={{overflowWrap: "break-word",fontSize:"12px"}}>Year|expense_name|expenses|branch_num|parent_split_point</p></strong>
                                <strong><p style={{overflowWrap: "break-word",fontSize:"12px"}}>2023|annual_salary|23500|income|Take Home Income</p></strong>
                                <strong><p style={{overflowWrap: "break-word",fontSize:"12px"}}>2023|income_tax|2184.20|tax|Take Home Income</p></strong>
                                <strong><p style={{overflowWrap: "break-word",fontSize:"12px"}}>2023|ni_tax|1311.60|tax|Take Home Income</p></strong>
                                <strong><p style={{overflowWrap: "break-word",fontSize:"12px"}}>2023|croatia_2024|2054|1|Take Home Income</p></strong>
                                <strong><p style={{overflowWrap: "break-word",fontSize:"12px"}}>2023|savings|400.0|1|Take Home Income</p></strong>
                                <strong><p style={{overflowWrap: "break-word",fontSize:"12px"}}>2023|food_work|910.0|1|Take Home Income</p></strong>
                                <strong><p style={{overflowWrap: "break-word",fontSize:"12px"}}>2023|clothing|600|2|Take Home Income</p></strong>
                                <strong><p style={{overflowWrap: "break-word",fontSize:"12px"}}>2023|croatia_2024| 250.0|2|Take Home Income</p></strong>
                                <strong><p style={{overflowWrap: "break-word",fontSize:"12px"}}>2023|savings|400.0|2|Take Home Income</p></strong>
                                

                            </div>

                        </div>
                        

                    </div>
                </div>

            </div>
            
            {graphimage !== null &&
            <div style={{position:"relative",top:"120px",left:media ? "4.7%":"12.5%",width:media ? "90%":"100px"}}>
                <img style={{borderRadius:"10px",width:media ? "auto":"280px"}} src={graphimage}></img>

            </div>}
            
               

        
            <div>
                
            </div>
        </div>

    )
}