import { useState, useEffect } from "react"
import axios from "axios";
import PaidIcon from '@mui/icons-material/Paid';
import { maxRowBasedquery } from "../mediamax";
import useMediaQuery from "../useMedia"
import { Alert, Button } from 'react-bootstrap'
export default function PotManage({potname,value,deletepot,deductpotstotal,deductions,setDeductions}){
    const [is_pot_deducted,setIsPotDeducted] = useState(false);
    const media = useMediaQuery(maxRowBasedquery)
    const check_deductions = async () =>{
        const response = await axios.get(`https://amarifinancegocardless-662756251108.us-central1.run.app/api/check_deduction?pot_name=${potname}`)
        let result = response.data
        if (result["message"] === "true"){
            setIsPotDeducted(true)
    
        }
        else{
            setIsPotDeducted(false)
        }
       }
    const delete_deduction = async () =>{
        const response = await axios.get(`https://amarifinancegocardless-662756251108.us-central1.run.app/api/delete_deduction?pot_name=${potname}`)
        let result = response.data
        if ("error" in result){
            alert(result.error)
        }
        else{
            const regex = /£(\d+)/;
            const deduct_exists = regex.test(potname)
            if (deduct_exists){
                const deduction = potname.match(regex)[1]
                setDeductions(deductions - deduction)
            }
            else{
                alert ("Pot name needs £<number>")
            }
     
            setIsPotDeducted(false)
        }
    }
    useEffect(() =>{
        check_deductions();
    },[])
    return(
        <div>
        <p  style={{width:media ? "300px": "250px",color:"black",backgroundColor:"white",borderRadius:"5px",padding:"5px"}} key={potname}>{potname}: £{value}</p> {/*onChange={(e) =>{handlepotchange(potname,e.target.value)}} */}

        <a style={{cursor:"pointer"}} onClick={() =>{deletepot(potname)}}>
            {media ? 
            <Button style={{backgroundColor:"red",border:"1px solid red"}}></Button>
            :
            <button style={{backgroundColor:"red",border:"1px solid red",height:"29px",width:"20px",position:"relative",top:"7.5px",borderTopRightRadius:"2px",borderBottomRightRadius:"2px"}}></button>}
        </a>
        <a style={{cursor:"pointer",marginLeft:"20px"}} onClick={() =>{if (is_pot_deducted === false){deductpotstotal(potname,value,setIsPotDeducted)}else{delete_deduction()}}}>
          
            <PaidIcon style={{color:is_pot_deducted ? "grey":"white",fontSize:media ? "30px" :"35px"}}/>

        </a>
    </div>
    )
}