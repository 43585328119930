
import useMediaQuery from "../useMedia"
import {maxRowBasedquery} from "../mediamax"

import { useNavigate } from 'react-router'
import { useSearchParams } from "react-router-dom"
import { useState } from "react"
import axios from "axios"
import LoadingSpinner from "../amarifinance/loaderspinner"
export default function CaesarAIUniAdmin(){
    const navigate = useNavigate()
    const [loading,setLoading] = useState(false);
    const [aptemotjinp,setAptemOTJInp] = useState({
        "month": "",
        "title": "",
        "message": "",
        "lecture_hours": "",
        "self_study_hours": "",
        "module": ""
    } )

    const [monthltyportfolio,setMonthlyPortfolio] = useState({"title":"","message":"","feedback":""});
    const [monthlyresult,setMonthlyResult] = useState("");

    const media = useMediaQuery(maxRowBasedquery)
    /*
          {
                "month": "august",
                "title": "tcip_model_1",
                "message": "describe the tcp/ip model",
                "lecture_hours": 3,
                "self_study_hours": 5,
                "module": "networking"
            }       */
            //setPersonInfo(prevPersonInfo => ({...prevPersonInfo, firstName: "name"}))
    const [usemonthly,setUseMonthly] = useState(false)
    
    const generateaptemreport = async () =>{
        if (aptemotjinp.message !== "" && aptemotjinp.module !== "" && aptemotjinp.month !== "" && aptemotjinp.title !== "" && aptemotjinp.lecture_hours !== "" && aptemotjinp.self_study_hours !== ""){
            setLoading(true)
            const url = `https://caesaraiaptemotj-qqbn26mgpa-uc.a.run.app/v1/generalreport?title=${aptemotjinp.title}_${aptemotjinp.module}_${aptemotjinp.month}&lecture_hours=${aptemotjinp.lecture_hours}&self_study_hours=${aptemotjinp.self_study_hours}&message=${aptemotjinp.message}`

            axios({
                url: url, //your url
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                // create file link in browser's memory
                const href = URL.createObjectURL(response.data);
            
                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', `${aptemotjinp.title}_${aptemotjinp.module}_${aptemotjinp.month}.txt`); //or any other extension
                document.body.appendChild(link);
                link.click();
            
                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
                //setAptemOTJInp(prevaptemotj => ({...prevaptemotj, title: "" ,module:"",month:""}))
                setLoading(false)
            });
            
        }

    }
    const generatemonthlyreport = async () =>{
        if (monthltyportfolio.title !== "" && monthltyportfolio.message !== ""){
            setLoading(true)
            const response = await axios.post("https://caesaraiaptemotj-qqbn26mgpa-uc.a.run.app/v1/monthlyreport",monthltyportfolio)
            let result = response.data
            setMonthlyResult(result)
            setLoading(false)
            

        }
    }


    return(
        <div style={{backgroundColor:"#1e1e1e",minHeight:media ? window.innerHeight :"270vh"}}>
            
            <div style={{position:"relative",top:"100px"}}>

  
                <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
                    <p style={{color:"white",fontSize:"45px"}}>CaesarAIUniAdmin</p>
                    <div style={{display:"flex",gap:"10px"}}>
                        <button onClick={() =>{setUseMonthly(false)}} style={{backgroundColor:!usemonthly ? "blue" :"#1e1e1e" ,color:"white",borderRadius:"5px"}}>CaesarrAI Aptem</button>
                        <button onClick={() =>{setUseMonthly(true)}}style={{backgroundColor:usemonthly ? "blue" :"#1e1e1e",color:"white",borderRadius:"5px"}}>CaesarAI Monthly Portfolio</button>
                    </div>
                </div>

                {!usemonthly ? 
                <div style={{marginTop:"20px"}}>
                    <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                        <p style={{color:"#FFCCCB"}}> PS. This takes approx 10 seconds each</p>
                    </div>
                    <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                        <input onChange={(e) =>{setAptemOTJInp(prevaptemotj => ({...prevaptemotj, title: e.target.value }))}} value={aptemotjinp.title} placeholder="Title: e.g tcip_model_1" style={{padding:"5px",borderRadius:"5px"}}></input>
                        <input onChange={(e) =>{setAptemOTJInp(prevaptemotj => ({...prevaptemotj, module: e.target.value }))}} value={aptemotjinp.module} placeholder="Module: e.g networking" style={{padding:"5px",borderRadius:"5px"}}></input>
                        
                    </div>
                    <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"20px"}}>
                        <input type="number" onChange={(e) =>{setAptemOTJInp(prevaptemotj => ({...prevaptemotj, self_study_hours: e.target.value }))}} value={aptemotjinp.self_study_hours} placeholder="Self Study Hours: e.g 5" style={{padding:"5px",borderRadius:"5px"}}></input>
                        <input type="number" onChange={(e) =>{setAptemOTJInp(prevaptemotj => ({...prevaptemotj, lecture_hours: e.target.value }))}} value={aptemotjinp.lecture_hours} placeholder="Lecture Hours: e.g 3" style={{padding:"5px",borderRadius:"5px"}}></input>
                        
                    </div>
                    <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"20px"}}>
                        <input onChange={(e) =>{setAptemOTJInp(prevaptemotj => ({...prevaptemotj, month: e.target.value }))}} value={aptemotjinp.month} placeholder="Month: e.g august" style={{padding:"5px",borderRadius:"5px"}}></input>
                                    
                    </div>
                    <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"20px"}}>
                        <textarea onChange={(e) =>{setAptemOTJInp(prevaptemotj => ({...prevaptemotj, message: e.target.value }))}} value={aptemotjinp.message} placeholder="Describe a subject to generate a report about: e.g describe the tcp/ip model" rows={10} style={{padding:"5px",borderRadius:"5px",width:"700px"}}></textarea>
                                    
                    </div>
                    {loading === false 
                    ?
                    <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"20px"}}>
                        <button onClick={() =>{generateaptemreport()}} style={{backgroundColor:"blue",color:"white",borderRadius:"5px",fontSize:"25px"}}>Submit</button>     
                    </div>
                    :
                    <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"20px"}}>
                    <LoadingSpinner/>
                    </div>
                    }
   
                </div>
                :
                <div style={{marginTop:"20px"}}>
                    <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                        <p style={{color:"#FFCCCB"}}> PS. This takes approx 10 seconds each</p>
                    </div>
                    <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"20px"}}>
                        <input onChange={(e) =>{setMonthlyPortfolio(prevmonthly => ({...prevmonthly, title:e.target.value }))}} value={monthltyportfolio.title} placeholder="KSB: e.g S10,K6" style={{padding:"5px",borderRadius:"5px"}}></input>
                    </div>
                    <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"20px"}}>
                        <textarea onChange={(e) =>{setMonthlyPortfolio(prevmonthly => ({...prevmonthly, message: e.target.value }))}} value={monthltyportfolio.message} placeholder="Message: Short paragraph that provides evidence about ksb." rows={10} style={{padding:"5px",borderRadius:"5px",width:"700px"}}></textarea>
                                    
                    </div>
                    <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"20px"}}>
                        <textarea onChange={(e) =>{setMonthlyPortfolio(prevmonthly => ({...prevmonthly, feedback: e.target.value }))}} value={monthltyportfolio.feedback} placeholder="Feedback: Any feedback provided by the DLC." rows={10} style={{padding:"5px",borderRadius:"5px",width:"700px"}}></textarea>
                                    
                    </div>
                    {monthlyresult !== "" &&
                    <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"20px"}}>
                            <textarea  rows={10} style={{padding:"5px",borderRadius:"5px",width:"700px"}}>{monthlyresult}</textarea>
                                        
                        </div>}
                    {loading === false 
                    ?
                    <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"20px"}}>
                        <button onClick={() =>{generatemonthlyreport()}} style={{backgroundColor:"blue",color:"white",borderRadius:"5px",fontSize:"25px"}}>Submit</button>     
                    </div>
                    :
                    <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"20px"}}>
                    <LoadingSpinner/>
                    </div>
                    }
   
                </div>
                    
                    }


            </div>

        </div>

    )
}